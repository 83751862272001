<template>
	<Toolbar @clickLeft="$router.push('/home')" @clickRight="$router.push('/search')">
		<template v-slot:start>
			<Icon class="h-5 w-5" icon="angleLeft" />
		</template>
		<p>{{ $t('categories.categories') }}</p>
		<template v-slot:end>
			<Icon class="h-5 w-5" icon="magnifyingGlass" />
		</template>
	</Toolbar>
	<div class="grid grid-cols-2 gap-5 p-4 pb-28">
		<div v-for="category in categories" :key="category.id" class="w-full items-center justify-center overflow-hidden break-all rounded-2xl bg-gray-100 py-3 text-center" @click="setCurrentCategory(category)">
			<img v-if="category.imageUrls[0]" class="mx-auto w-16 rounded-t-md object-cover py-2" :src="category.imageUrls[0]" alt="" loading="lazy" />
			<p class="text-sm">{{ category.name[currentLang] }}</p>
		</div>
	</div>
</template>

<script>
import { storeToRefs } from 'pinia'
// import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
import { useSharedStore } from '@/store/shared'
import { useCatalogStore } from '@/store/catalog'

export default {
	setup() {
		// const route = useRoute()
		const router = useRouter()
		// const store = useStore()
		const { currentLang } = storeToRefs(useSharedStore())
		const { catalog, category } = storeToRefs(useCatalogStore())
		const categories = catalog.value[0].categories

		function setCurrentCategory(val) {
			category.value = val
			router.push(`/categories/${val.id}`)
		}
		// const menuType = computed(() => route.query.menuType)
		// const categories = computed(() => store.getters.categories(menuType.value))

		// onMounted(() => {
		// 	if (menuType.value !== 'MYJETMALL' && menuType.value !== 'LIFESTYLE') router.push('/home')
		// })

		return {
			currentLang,
			categories,
			category,
			setCurrentCategory,
		}
	},
}
</script>
